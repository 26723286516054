import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Embeded from "../components/Embeded";
import BottomPanel from "../components/BottomPanel";

export default function Visualizadores() {
  return (
    <div className="Visualizadores">
      <Navbar1 />
      <Navbar2 />
      <Embeded />
      <BottomPanel />
    </div>
  );
}
