import React, { useState, useEffect } from "react";
import "../css/BottomPanel.css";
import icon5 from "../images/icono-contacto.svg";
import icon6 from "../images/icono-correo.svg";
import direccion from "../images/direccion.png";
import axios from "axios";

function BottomPanel() {
  const [contactos, setContactos] = useState([]);
  const [logos, setLogos] = useState([]);
  const [links, setLinks] = useState([]);

  const fetchContactos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/footer.php`
    );
    setContactos(response.data);
  };

  const fetchLogos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/footer_2.php`
    );
    setLogos(response.data);
  };

  const fetchRedes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/footer_3.php`
    );
    setLinks(response.data);
  };

  useEffect(() => {
    fetchContactos();
    fetchLogos();
    fetchRedes();
  }, []);

  return (
    <div className="bottomPanel">
      {contactos.map((contacto) => (
        <div className="panelContacto">
          <h1>{contacto.ciudad}</h1>
          <div>
            <img className="imagenDireccion" src={direccion} alt="icon4" />
            <h3>{contacto.direccion}</h3>
          </div>
          <div>
            <img src={icon6} alt="icon5" />
            <h3>{contacto.correo}</h3>
          </div>
          <div>
            <img src={icon5} alt="icon6" />
            <h3>{contacto.telefono}</h3>
          </div>
        </div>
      ))}
      <div className="panelDepartamentos">
        <div className="logosDpto">
          {logos.map((logo) => (
            <div>
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${logo.imagen}`}
                alt="logo"
              />
            </div>
          ))}
        </div>
        <h2 className="laboratorio">
          Laboratorio de Prospección, Monitoreo y Modelación de Recursos
          Agrícolas y Ambientales (PROMMRA) - Universidad de La Serena
        </h2>
      </div>
      <div className="panelRedes">
        <h2 className="siguenos">
          ¡Síguenos en redes sociales para enterarte de nuestro trabajo!
        </h2>
        <div className="iconosRedes">
          {links.map((red) => (
            <div>
              <a href={red.link} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${red.imagen}`}
                  alt="icon1"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BottomPanel;
