import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Lineas from "../components/Lineas";
import BottomPanel from "../components/BottomPanel";

export default function Invetigacion() {
  return (
    <div className="Invetigacion">
      <Navbar1 />
      <Navbar2 />
      <Lineas />
      <BottomPanel />
    </div>
  );
}
