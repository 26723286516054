import React, { useState, useEffect } from "react";
import "../css/Embeded.css";
import axios from "axios";

function Embeded() {
  const [visualizadores, setVisualizadores] = useState([]);
  const [visualizadores_2, setVisualizadores_2] = useState([]);
  const [iframeUrl, setIframeUrl] = useState("");
  const [text, setText] = useState("");

  const fetchVisualizadores = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/visualizador.php`
    );
    setVisualizadores(response.data);
  };

  const fetchVisualizadores_2 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/visualizador_2.php`
    );
    setVisualizadores_2(response.data);
    if (response.data.length > 0) {
      setIframeUrl(response.data[0].link);
      setText(response.data[0].descripcion);
    }
  };

  useEffect(() => {
    fetchVisualizadores();
    fetchVisualizadores_2();
  }, []);

  const handleUrlChange = (event) => {
    const url = event.target.value;
    setIframeUrl(url);

    // Actualizar el texto basado en la URL seleccionada
    const selectedVisualizador = visualizadores_2.find(
      (visualizador) => visualizador.link === url
    );

    if (selectedVisualizador) {
      setText(selectedVisualizador.descripcion);
    } else {
      setText("");
    }
  };

  return (
    <div className="framePanel">
      {visualizadores.map((visualizador) => (
        <div key={visualizador.id}>
          <h3>{visualizador.titulo}</h3>
          <p className="presentacionSuelo">{visualizador.presentacion}</p>
        </div>
      ))}
      <label htmlFor="urlSelector">Selecciona una zona:</label>
      <select onChange={handleUrlChange} value={iframeUrl}>
        {visualizadores_2.map((visualizador_2) => (
          <option key={visualizador_2.id} value={visualizador_2.link}>
            {visualizador_2.zona}
          </option>
        ))}
      </select>

      <p className="descripcionZona">{text}</p>
      <iframe className="embeded" src={iframeUrl} loading="lazy"></iframe>
    </div>
  );
}

export default Embeded;
