import React from "react";
import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import ImageSlider from "../components/ImageSlider";
import MainPanel from "../components/MainPanel";
import BottomPanel from "../components/BottomPanel";
import AvisoConstruccion from "../components/AvisoConstruccion";

function Inicio() {
  return (
    <div className="Inicio">
      {/*<AvisoConstruccion />*/}
      <Navbar1 />
      <Navbar2 />
      <div className="backgroundPanel">
        <ImageSlider />
        <MainPanel />
      </div>
      <BottomPanel />
    </div>
  );
}

export default Inicio;
