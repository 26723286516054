import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Formulario from "../components/Formulario";
import BottomPanel from "../components/BottomPanel";

export default function Contactanos() {
  return (
    <div className="Contactanos">
      <Navbar1 />
      <Navbar2 />
      <Formulario />
      <BottomPanel />
    </div>
  );
}
