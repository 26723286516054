import React, { useState, useEffect } from "react";
import "../css/PanelProyectos.css";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";

export default function PanelProyectos() {
  const [proyectos, setProyectos] = useState([]);
  const [filteredProyectos, setFilteredProyectos] = useState([]);
  const [currentYear, setCurrentYear] = useState("");
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchProyectos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/proyectos.php`
    );
    const data = response.data;
    setProyectos(data);

    if (data.length > 0) {
      const firstYear = data[0].year;
      const filtered = data.filter((proyecto) => proyecto.year === firstYear);
      setFilteredProyectos(filtered);
      setCurrentYear(firstYear);
      setActiveButtonId(firstYear);
    }
  };

  useEffect(() => {
    fetchProyectos();
  }, []);

  const handleButtonClick = (year) => {
    const filtered = proyectos.filter((proyecto) => proyecto.year === year);
    setFilteredProyectos(filtered);
    setCurrentYear(year);
    setActiveButtonId(year);
    setCurrentIndex(0);
  };

  const renderButtons = [
    ...new Set(proyectos.map((proyecto) => proyecto.year)),
  ].map((year) => (
    <button
      key={year}
      onClick={() => handleButtonClick(year)}
      style={{
        fontFamily:
          activeButtonId === year ? "GarnetCapitalsBold" : "Montserrat",
      }}>
      {year}
    </button>
  ));

  function goToPrevPanel() {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }

  function goToNextPanel() {
    const maxIndex = Math.ceil(filteredProyectos.length / 3) - 1;
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="añosProyectos">
      <div className={`años ${isOpen ? "open" : ""}`}>
        <div className="hamburgerAños" onClick={() => setIsOpen(!isOpen)}>
          &#9776;
        </div>
        <h2>Filtrar búsqueda por año</h2>
        {renderButtons}
      </div>
      <div className="botonesProyectos">
        <SwipeableViews
          className="proyectos"
          enableMouseEvents
          index={currentIndex}
          onChangeIndex={(index) => setCurrentIndex(index)}>
          {filteredProyectos.length > 0 ? (
            [...Array(Math.ceil(filteredProyectos.length / 3))].map((_, i) => (
              <div className="contenidoProyectos" key={`tab-${i}`}>
                <h2>Proyectos {currentYear}</h2>
                {filteredProyectos
                  .slice(i * 3, (i + 1) * 3)
                  .map((proyecto, index) => (
                    <div key={`combo-${i * 3 + index}`}>
                      <h3>{proyecto.titulo}</h3>
                      <p>{proyecto.descripcion}</p>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <div>
              <h2>Proyectos {currentYear}</h2>
              <p>No hay proyectos para este año.</p>
            </div>
          )}
        </SwipeableViews>
        <div className="botonesAntSig">
          <button onClick={goToPrevPanel} disabled={currentIndex === 0}>
            Anterior
          </button>
          <p className="pageIndex">{currentIndex + 1}</p>
          <button
            onClick={goToNextPanel}
            disabled={
              currentIndex === Math.ceil(filteredProyectos.length / 3) - 1
            }>
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
}
