import React, { useState } from "react";
import Collapsible from "react-collapsible";
import "../css/Dashboard.css";
import Crud_Noticias from "../components/Crud_Noticias";
import Crud_Redes from "../components/Crud_Redes.js";
import Crud_Reportes from "../components/Crud_Reportes";
import Crud_Eventos from "../components/Crud_Eventos";
import Crud_Prensa from "../components/Crud_Prensa";
import Crud_Banner from "../components/Crud_Banner";
import Crud_Miembros from "../components/Crud_Miembros";
import Crud_Que_Hacemos from "../components/Crud_Que_Hacemos";
import Crud_Que_Hacemos_2 from "../components/Crud_Que_Hacemos_2";
import Crud_Que_Hacemos_3 from "../components/Crud_Que_Hacemos_3";
import Crud_Proyectos from "../components/Crud_Proyectos";
import Crud_Servicios from "../components/Crud_Servicios";
import Crud_Laboratorio from "../components/Crud_Laboratorio";
import Crud_Laboratorio_2 from "../components/Crud_Laboratorio_2";
import Crud_Laboratorio_3 from "../components/Crud_Laboratorio_3";
import Crud_Investigacion from "../components/Crud_Investigacion";
import Crud_Visualizador from "../components/Crud_Visualizador";
import Crud_Visualizador_2 from "../components/Crud_Visualizador_2";
import Crud_Footer from "../components/Crud_Footer";
import Crud_Footer_2 from "../components/Crud_Footer_2";
import Crud_Footer_3 from "../components/Crud_Footer_3";
import Inicio_Dashboard from "../components/Inicio_Dashboard";

function Dashboard() {
  const [activeForm, setActiveForm] = useState("Inicio_Dashboard");

  const renderForm = () => {
    switch (activeForm) {
      case "Crud_Noticias":
        return <Crud_Noticias />;
      case "Crud_Redes":
        return <Crud_Redes />;
      case "Crud_Reportes":
        return <Crud_Reportes />;
      case "Crud_Eventos":
        return <Crud_Eventos />;
      case "Crud_Prensa":
        return <Crud_Prensa />;
      case "Crud_Banner":
        return <Crud_Banner />;
      case "Crud_Miembros":
        return <Crud_Miembros />;
      case "Crud_Que_Hacemos":
        return <Crud_Que_Hacemos />;
      case "Crud_Que_Hacemos_2":
        return <Crud_Que_Hacemos_2 />;
      case "Crud_Que_Hacemos_3":
        return <Crud_Que_Hacemos_3 />;
      case "Crud_Proyectos":
        return <Crud_Proyectos />;
      case "Crud_Servicios":
        return <Crud_Servicios />;
      case "Crud_Laboratorio":
        return <Crud_Laboratorio />;
      case "Crud_Laboratorio_2":
        return <Crud_Laboratorio_2 />;
      case "Crud_Laboratorio_3":
        return <Crud_Laboratorio_3 />;
      case "Crud_Investigacion":
        return <Crud_Investigacion />;
      case "Crud_Visualizador":
        return <Crud_Visualizador />;
      case "Crud_Visualizador_2":
        return <Crud_Visualizador_2 />;
      case "Crud_Footer":
        return <Crud_Footer />;
      case "Crud_Footer_2":
        return <Crud_Footer_2 />;
      case "Crud_Footer_3":
        return <Crud_Footer_3 />;
      case "Inicio_Dashboard":
        return <Inicio_Dashboard />;
    }
  };

  return (
    <div className="Dashboard">
      <nav className="Sidebar">
        <h1 onClick={() => setActiveForm("Inicio_Dashboard")}>Admin</h1>
        <h3>Componentes</h3>
        <Collapsible trigger="Inicio">
          <button onClick={() => setActiveForm("Crud_Noticias")}>
            Noticias
          </button>
          <button onClick={() => setActiveForm("Crud_Redes")}>
            Redes Sociales
          </button>
          <button onClick={() => setActiveForm("Crud_Reportes")}>
            Reporte de Embalses
          </button>
          <button onClick={() => setActiveForm("Crud_Eventos")}>
            Próximos Eventos
          </button>
          <button onClick={() => setActiveForm("Crud_Prensa")}>
            Prommra en Prensa
          </button>
          <button onClick={() => setActiveForm("Crud_Banner")}>
            Banner Principal
          </button>
        </Collapsible>
        <Collapsible trigger="¿Quiénes somos?">
          <button onClick={() => setActiveForm("Crud_Miembros")}>
            Equipo Prommra
          </button>
          <button onClick={() => setActiveForm("Crud_Que_Hacemos")}>
            ¿Qué hacemos?
          </button>
          <button onClick={() => setActiveForm("Crud_Que_Hacemos_2")}>
            Lineas de trabajo
          </button>
          <button onClick={() => setActiveForm("Crud_Que_Hacemos_3")}>
            Servicios
          </button>
        </Collapsible>
        <Collapsible trigger="Proyectos" className="Colapasable">
          <button onClick={() => setActiveForm("Crud_Proyectos")}>
            Proyectos
          </button>
        </Collapsible>
        <Collapsible trigger="Servicios" className="Colapasable">
          <button onClick={() => setActiveForm("Crud_Servicios")}>
            Servicios
          </button>
        </Collapsible>
        <Collapsible trigger="Líneas de Investigación" className="Colapasable">
          <button onClick={() => setActiveForm("Crud_Laboratorio")}>
            Laboratorio Presentación
          </button>
          <button onClick={() => setActiveForm("Crud_Laboratorio_2")}>
            Laboratorio Imágenes
          </button>
          <button onClick={() => setActiveForm("Crud_Laboratorio_3")}>
            Laboratorio Capacidades
          </button>
          <button onClick={() => setActiveForm("Crud_Investigacion")}>
            Líneas de Investigación
          </button>
        </Collapsible>
        <Collapsible trigger="Visualizadores de Suelo" className="Colapasable">
          <button onClick={() => setActiveForm("Crud_Visualizador")}>
            Presentación
          </button>
          <button onClick={() => setActiveForm("Crud_Visualizador_2")}>
            Zonas de Visualización
          </button>
        </Collapsible>
        <Collapsible trigger="Footer" className="Colapasable">
          <button onClick={() => setActiveForm("Crud_Footer")}>
            Datos de contacto
          </button>
          <button onClick={() => setActiveForm("Crud_Footer_2")}>
            Logos Universidad
          </button>
          <button onClick={() => setActiveForm("Crud_Footer_3")}>
            Redes Sociales
          </button>
          {/*<button onClick={() => setActiveForm("#")}>Texto Extra</button>*/}
        </Collapsible>
      </nav>
      <div className="mainDashboard">
        <nav className="Topbar">Dashboard</nav>
        {renderForm()}
      </div>
    </div>
  );
}

export default Dashboard;
