import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import PanelProyectos from "../components/PanelProyectos";
import BottomPanel from "../components/BottomPanel";

export default function Proyectos() {
  return (
    <div className="Proyectos">
      <Navbar1 />
      <Navbar2 />
      <PanelProyectos />
      <BottomPanel />
    </div>
  );
}
