import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import ServiciosPanel from "../components/ServiciosPanel";
import BottomPanel from "../components/BottomPanel";

export default function Servicios() {
  return (
    <div className="panelPrincipal">
      <Navbar1 />
      <Navbar2 />
      <ServiciosPanel />
      <BottomPanel />
    </div>
  );
}
