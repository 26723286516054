import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import "../css/Lineas.css";
import image1 from "../images/1.svg";
import image2 from "../images/2.svg";
import image3 from "../images/3.svg";
import image4 from "../images/4.svg";
import prospeccion from "../images/Prospeccion.svg";
import monitoreo from "../images/Monitoreo.svg";
import modelacion from "../images/Modelacion.svg";
import linea1 from "../images/hidrometria.svg";
import linea2 from "../images/modelacion-hidrologica.svg";
import linea3 from "../images/geomatica-aplicada.svg";
import linea4 from "../images/formacion-Transferencia.svg";
import linea5 from "../images/gestion-de-Recursos.svg";
import axios from "axios";

function Lineas() {
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [laboratorio, setLaboratorio] = useState([]);
  const [laboratorio_2, setLaboratorio_2] = useState([]);
  const [laboratorio_3, setLaboratorio_3] = useState([]);
  const [investigacion, setInvestigacion] = useState([]);

  const fetchLaboratorio = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio.php`
    );
    setLaboratorio(response.data);
  };

  const fetchLaboratorio_2 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio_2.php`
    );
    setLaboratorio_2(response.data);
  };

  const fetchLaboratorio_3 = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratorio_3.php`
    );
    setLaboratorio_3(response.data);
  };

  const fetchInvestigacion = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/investigacion.php`
    );
    setInvestigacion(response.data);
  };

  useEffect(() => {
    fetchLaboratorio();
    fetchLaboratorio_2();
    fetchLaboratorio_3();
    fetchInvestigacion();
  }, []);

  return (
    <div className="panelLineas">
      <div className="selectorLineas">
        <button
          className={index === 0 ? "active" : ""}
          onClick={() => handleChangeIndex(0)}>
          Laboratorio
        </button>
        <button
          className={index === 1 ? "active" : ""}
          onClick={() => handleChangeIndex(1)}>
          Lineas de Investigación
        </button>
      </div>
      <SwipeableViews
        className="swipeableLineas"
        enableMouseEvents
        index={index}
        onChangeIndex={handleChangeIndex}>
        <div className="primeraPestaña">
          <div className="panelCentrado1">
            <h2>¿Qué hacemos en el laboratorio Prommra?</h2>
            {laboratorio.map((laboratorio) => (
              <div>
                <h2 className="añoLinea">{laboratorio.year}</h2>
                <p>{laboratorio.presentacion}</p>
              </div>
            ))}

            <div className="imagenesPrimeraPestañas">
              {laboratorio_2.map((laboratorio_2) => (
                <div className="imagenPrommra">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${laboratorio_2.imagen}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedImage(
                        `${process.env.REACT_APP_API_URL}/uploads/${laboratorio_2.imagen}`
                      );
                    }}
                    loading="lazy"></img>
                </div>
              ))}
            </div>
            <div className="principios">
              {laboratorio_3.map((laboratorio_3) => (
                <div className="principio">
                  <h3>{laboratorio_3.titulo}</h3>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${laboratorio_3.imagen}`}
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedImage(
                        `${process.env.REACT_APP_API_URL}/uploads/${laboratorio_3.imagen}`
                      );
                    }}
                    loading="lazy"></img>
                  <p>{laboratorio_3.descripcion}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="segundaPestaña">
          <h2>Líneas de investigación</h2>
          {laboratorio.map((laboratorio) => (
            <div>
              <h2 className="añoLinea">{laboratorio.year}</h2>
            </div>
          ))}
          <div className="lineas">
            {investigacion.map((investigacion) => (
              <div className="linea">
                <h3>{investigacion.titulo}</h3>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${investigacion.imagen}`}
                  onClick={() => {
                    setIsModalOpen(true);
                    setSelectedImage(
                      `${process.env.REACT_APP_API_URL}/uploads/${investigacion.imagen}`
                    );
                  }}
                  loading="lazy"></img>
                <p>{investigacion.descripcion}</p>
              </div>
            ))}
          </div>
        </div>
      </SwipeableViews>
      {isModalOpen && selectedImage && (
        <div className="modalGeneral" onClick={() => setIsModalOpen(false)}>
          <div
            className="modalContentGeneral"
            onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} loading="lazy"></img>
          </div>
        </div>
      )}
    </div>
  );
}

export default Lineas;
