import React, { useState, useEffect } from "react";
import "../css/MainPanel.css";
import axios from "axios";

function MainPanel() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [noticias, setNoticias] = useState([]);
  const [redes, setRedes] = useState([]);
  const [reportes, setReportes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [prensa, setPrensa] = useState([]);

  const fetchNoticias = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/noticias.php`
    );
    setNoticias(response.data);
  };

  const fetchRedes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/redes.php`
    );
    setRedes(response.data);
  };

  const fetchReportes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reportes.php`
    );
    setReportes(response.data);
  };

  const fetchEventos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/eventos.php`
    );
    setEventos(response.data);
  };

  const fetchPrensa = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/prensa.php`
    );
    setPrensa(response.data);
  };

  useEffect(() => {
    fetchNoticias();
    fetchRedes();
    fetchReportes();
    fetchEventos();
    fetchPrensa();
  }, []);

  return (
    <div className="mainPanel">
      <div className="leftPanel">
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Síguenos en redes sociales</h1>
        <div className="redesSociales">
          {redes.map((red) => (
            <a href={red.link} target="_blank">
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${red.imagen}`}
                loading="lazy"></img>
              {red.cuenta}
            </a>
          ))}
        </div>
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Reporte de Embalses</h1>
        {reportes.map((reporte) => (
          <img
            className="reporte_embalses"
            src={`${process.env.REACT_APP_API_URL}/uploads/${reporte.imagen}`}
            onClick={() => {
              setIsModalOpen(true);
              setSelectedImage(
                `${process.env.REACT_APP_API_URL}/uploads/${reporte.imagen}`
              );
            }}
            loading="lazy"></img>
        ))}
      </div>
      <div className="centralPanel">
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Noticias</h1>

        {noticias.length > 0 ? (
          noticias.map((noticia, index) => (
            <div className="contenedorNoticias" key={index}>
              <h1 className="tituloNoticas">{noticia.titulo}</h1>
              <h3 className="fechaNoticia">{noticia.fecha}</h3>
              <div className="imagenDescripcion">
                <img
                  className="imagenNoticia"
                  src={`${process.env.REACT_APP_API_URL}/uploads/${noticia.imagen}`}
                  alt={noticia.titulo}
                  onClick={() => {
                    setIsModalOpen(true);
                    setSelectedImage(
                      `${process.env.REACT_APP_API_URL}/uploads/${noticia.imagen}`
                    );
                  }}
                />
                <p className="descripcionNoticia">{noticia.descripcion}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No se encontraron noticias.</p>
        )}
      </div>
      <div className="rightPanel">
        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Próximos eventos</h1>
        {eventos.map((evento) => (
          <img
            className="imagenEventos"
            src={`${process.env.REACT_APP_API_URL}/uploads/${evento.imagen}`}
            onClick={() => {
              setIsModalOpen(true);
              setSelectedImage(
                `${process.env.REACT_APP_API_URL}/uploads/${evento.imagen}`
              );
            }}
            loading="lazy"></img>
        ))}

        <hr className="separadorPrincipal"></hr>
        <h1 className="tituloPrincial">Prommra en Prensa</h1>
        {prensa.map((prensa) => (
          <div className="prensa">
            <img
              className="imagenPrensa"
              src={`${process.env.REACT_APP_API_URL}/uploads/${prensa.imagen}`}
              loading="lazy"></img>
            <a href={prensa.link} className="tituloNoticas" target="_blank">
              {prensa.titulo}
            </a>
          </div>
        ))}
      </div>
      {isModalOpen && selectedImage && (
        <div className="modalGeneral" onClick={() => setIsModalOpen(false)}>
          <div
            className="modalContentGeneral"
            onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} loading="lazy"></img>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainPanel;
