import React, { useState } from "react";
import logo from "../images/logo-prommra.png";
import "../css/Navbar1.css";

function Navbar1() {
  // Estado para manejar si el menú está abierto o cerrado
  const [isOpen, setIsOpen] = useState(false);

  // Función para cambiar el estado del menú
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar1 ${isOpen ? "open" : ""}`}>
      <div className="panelLogo">
        <a href="/inicio" rel="noreferrer">
          <img className="logo" src={logo} alt="Logo Prommra" />
        </a>
      </div>
      {/* Botón hamburguesa */}
      <div className={`nav-items1 ${isOpen ? "open" : ""}`}>
        <div className="hamburger1" onClick={toggleMenu}>
          &#9776;
        </div>
        <a href="https://www.userena.cl/ " target="_blank">
          Universidad de La Serena
        </a>
        <a href="https://fciencias.userena.cl/" target="_blank">
          Facultad de Ciencias
        </a>
        {/*<a href="#">Departamento de Agronomía</a>*/}
        <a href="https://consorcioquitaianko.cl/" target="_blank">
          Quitai-Anko
        </a>
        {/*<a href="#">Intranet</a>*/}
        <a href="/contactanos">Contáctanos</a>
      </div>
    </nav>
  );
}

export default Navbar1;
