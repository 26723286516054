import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import "../css/Formulario.css";

function Formulario() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  function onChange(value) {
    //console.log("Captcha value:", value);
  }

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm("service_dp3l3n7", "template_kipjc5c", form.current, {
        publicKey: "G_7XH66lpB_aj_PTt",
      })
      .then(
        () => {
          //console.log("SUCCESS!");
          e.target.reset();
          toast.success("Solicitud envíada con éxito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          recaptchaRef.current.reset();
        },
        (error) => {
          //console.log("FAILED...", error.text);
          toast.error("Hubo un error en el envío", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          recaptchaRef.current.reset();
        }
      );
  };

  //paola.rojasa1@userena.cl, loreto.flores@userena.cl, edward.paez@userena.cl
  return (
    <form ref={form} className="panelForm" onSubmit={sendEmail}>
      <h1>¡Contáctanos!</h1>
      <h3>Nuestro equipo se pondrá en contacto con Usted</h3>
      <div className="campos">
        <div className="formNombre">
          <h4>Nombre</h4>
          <input type="text" name="user_name" required />
        </div>
        <div className="formApellido">
          <h4>Apellido</h4>
          <input type="text" name="user_last_name" required />
        </div>
        <div className="formCorreo">
          <h4>Correo electrónico</h4>
          <input type="email" name="user_email" required />
        </div>
        <div className="formOrganizacion">
          <h4>Organización</h4>
          <input type="text" name="user_organization" required />
        </div>
        <div className="Ubicacion">
          <h4>Encuentranos en Av. La Paz #1108, Ovalle</h4>
          <iframe
            className="gps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.331227507678!2d-70.83444948479805!3d-30.602918981695373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5410425af2f%3A0x76bd7c8a5c3e72a1!2sAv.%20La%20Paz%201108%2C%20Ovalle%2C%20Coquimbo!5e0!3m2!1ses!2scl!4v1642612345678"
            loading="lazy"></iframe>
        </div>
        <div className="formComentario">
          <h4>Comentario o mensaje</h4>
          <textarea name="message" required />
        </div>
      </div>
      <div className="captcha">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Le8QiMqAAAAANGHAksTC83Hfs5vZQtxBXnhZyeK"
          onChange={onChange}
        />
      </div>
      <div className="enviarCargando">
        <input className="btnEnviar" type="submit" value="Enviar" />
        {loading && <p className="textoCargando">Cargando...</p>}
      </div>

      <ToastContainer />
    </form>
  );
}

export default Formulario;
