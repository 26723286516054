import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import "../css/Crud.css";

Modal.setAppElement("#root"); // Asegúrate de que el ID coincida con el de tu elemento root en el HTML

function App() {
  const [image, setImage] = useState(null);
  const [items, setItems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Estado para el modal de edición y los campos del formulario de edición
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [editId, setEditId] = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);

  //Llama al método GET para recibir los datos de la base de datos
  const fetchItems = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/Crud_Footer_2.php`
    );
    setItems(response.data);
  };

  //Muestra la tabla al iniciar la página
  useEffect(() => {
    fetchItems();
  }, []);

  //Maneja la subida de datos a la base de datos
  const handleSubmit = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    // Crea un objeto FormData y lo rellena con la información del formulario
    const formData = new FormData();
    formData.append("image", image);
    formData.append("action", "new");

    // Crear un objeto para almacenar los datos de FormData y poder imprimirlos en consola
    const formDataObj = {};
    formData.forEach((value, key) => {
      formDataObj[key] = value;
    });

    //Envía el FormData a al backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Crud_Footer_2.php`,
        formData
      );

      console.log(response.data); // Imprime la respuesta exitosa
      console.log(formDataObj);
    } catch (error) {
      //console.error("Error al enviar los datos:", error); // Imprime la respuesta fallida
      //console.log(formDataObj);
      if (error.response.status === 415) {
        alert(
          "El archivo subido no es una imagen. Por favor, suba una imagen ."
        );
        return;
      }
      if (error.response.status === 416) {
        alert(
          "La imagen ya existe en la página. Por favor, suba una imagen diferente."
        );
        return;
      }
      if (error.response.status === 417) {
        alert(
          "La imagen es muy pesada. Por favor, suba una imagen más ligera. Máximo 16MB."
        );
        return;
      }
    }
    fetchItems(); // Actualiza la tabla
    closeModal(); // Cierra el modal
  };

  // Función para abrir el modal de edición y establecer los valores de los inputs
  const handleEdit = (item) => {
    setEditId(item.id);
    setEditImage(item.imagen);
    openEditModal();
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (editImage) {
      const formData = new FormData();
      formData.append("editId", editId); // Asocia la imagen al registro con el ID
      formData.append("image", editImage);
      formData.append("action", "update_image");

      try {
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/Crud_Footer_2.php`, // Un endpoint separado para manejar la imagen
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Especifica que estás enviando un archivo
            },
          }
        );

        console.log(imageResponse.data); // Imprime la respuesta exitosa de la carga de la imagen
      } catch (error) {
        console.error("Error al enviar la imagen:", error); // Imprime la respuesta fallida
        if (error.response.status === 415) {
          alert(
            "El archivo subido no es una imagen. Por favor, suba una imagen."
          );
          return;
        }
        if (error.response.status === 416) {
          alert(
            "La imagen ya existe en la página. Por favor, suba una imagen diferente."
          );
          return;
        }
        if (error.response.status === 417) {
          alert(
            "La imagen es muy pesada. Por favor, suba una imagen más ligera. Máximo 16MB."
          );
          return;
        }
      }
    }

    fetchItems(); // Actualiza la tabla
    closeEditModal(); // Cierra el modal de edición
  };

  const moveItem = (index, direction) => {
    let newItems = [...items];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Si el índice de destino está fuera de los límites, no hacemos nada
    if (targetIndex < 0 || targetIndex >= newItems.length) return;

    // Intercambiar los elementos
    const temp = newItems[index];
    newItems[index] = newItems[targetIndex];
    newItems[targetIndex] = temp;

    setItems(newItems);

    // Enviar el nuevo orden al backend
    updateOrderInDatabase(newItems);
  };

  const updateOrderInDatabase = async (newItems) => {
    const formData = new FormData();
    formData.append("action", "update_order");
    newItems.forEach((item, index) => {
      formData.append(`items[${index}][id]`, item.id);
      formData.append(`items[${index}][order]`, index);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Crud_Footer_2.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error al actualizar el orden:", error);
    }
  };
  //Maneja la eliminación de datos de la base de datos
  const handleDelete = async (id, imagen) => {
    try {
      //Envía el id y la imagen a eliminar al backend
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/Crud_Footer_2.php`,
        {
          data: { id, imagen },
        }
      );
      console.log(response.data); // Imprime la respuesta exitosa
    } catch (error) {
      console.error("Error al eliminar los datos:", error); // Imprime la respuesta fallida
    }
    fetchItems(); // Actualiza la tabla
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Crear una URL de objeto para la vista previa
    } else {
      setPreviewUrl(null);
    }
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Crear una URL de objeto para la vista previa
    } else {
      setPreviewUrl(null);
    }
  };

  //Abre el modal
  const openModal = () => {
    setModalIsOpen(true);
    setPreviewUrl(null);
  };

  //Cierra el modal
  const closeModal = () => {
    setModalIsOpen(false);
    setPreviewUrl(null);
  };
  //Abre el modal Edit
  const openEditModal = () => {
    setEditModalIsOpen(true);
    setPreviewUrl(null);
  };

  //Cierra el modal Edit
  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setPreviewUrl(null);
  };

  const textos = {
    titulo: "Logos Universidad",
    addButton: "Añadir Logos",
    columna1: "Imagen",
  };

  return (
    <div className="App">
      <h1>{textos.titulo}</h1>
      <button className="añadirFila" onClick={openModal}>
        {textos.addButton}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Formulario de CRUD"
        className="modalDashboard"
        overlayClassName="modal-overlay">
        <form onSubmit={handleSubmit}>
          <h1>{textos.addButton}</h1>
          <div className="selectorImagen">
            <label>{textos.columna1}:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange} // Asigna la imagen seleccionada a la variable imagen
              required
            />
          </div>
          {previewUrl && (
            <div>
              <label>Vista previa de la imagen:</label>
              <img
                src={previewUrl}
                alt="Vista previa"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
          <div className="submitCancel">
            <button onClick={closeModal}>Cancelar</button>
            <button type="submit">Añadir</button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Formulario de Edición"
        className="modalDashboard"
        overlayClassName="modal-overlay">
        <form onSubmit={handleEditSubmit}>
          <h1>Editar</h1>

          <div>
            <label>{textos.columna1}:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleEditImageChange}
            />
          </div>
          {previewUrl && (
            <div>
              <label>Vista previa de la imagen:</label>
              <img
                src={previewUrl}
                alt="Vista previa"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
          <div className="submitCancel">
            <button onClick={closeEditModal}>Cancelar</button>
            <button type="submit">Guardar Cambios</button>
          </div>
        </form>
      </Modal>
      <table className="crud-table">
        {/* Nombres de cada columna*/}
        <thead>
          <tr>
            <th>{textos.columna1}</th>
            <th>Acciones</th>
            <th>Mover</th>
          </tr>
        </thead>
        {/* Mapea los datos de la base de datos en la tabla */}
        <tbody className="crudBody">
          {items.map((item, index) => (
            <tr key={item.id}>
              <td>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${item.imagen}`}
                  className="crud-image"
                />
                {item.imagen}
              </td>
              <td className="editarEliminar">
                <button onClick={() => handleEdit(item)}>Editar</button>
                {/* Botón para eliminar */}
                <button onClick={() => handleDelete(item.id, item.imagen)}>
                  Eliminar
                </button>
              </td>
              <td>
                <button onClick={() => moveItem(index, "up")}>↑</button>
                <button onClick={() => moveItem(index, "down")}>↓</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
