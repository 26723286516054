import "../css/Inicio.css";
import Navbar1 from "../components/Navbar1";
import Navbar2 from "../components/Navbar2";
import Miembros from "../components/Miembros";
import BottomPanel from "../components/BottomPanel";

export default function Quienes_somos() {
  return (
    <div className="Quienes_somos">
      <Navbar1 />
      <Navbar2 />
      <Miembros />
      <BottomPanel />
    </div>
  );
}
